import { Form, Input } from 'antd';
import React, { useState } from 'react';
import FormPage from '../../../../../components/pages/FormPage';

const DefModel = {
    name: ''
}

export default () => {
    const [model, setModel] = useState(DefModel);
    return (
        <FormPage
            url={'api/v1/consignee'}
            formValid={false}
            model={model}
            setModel={setModel}
            wrapperCol={{ span: 8 }}
            labelCol={{ span: 3 }}
            title={"Consignee"}
            emptyModel={DefModel}
            callbackPath={"/master/consignee"}
        >
            <Form.Item name={'name'} label="name" rules={[{ required: true }]} >
                <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} autoFocus />
            </Form.Item>
        </FormPage>
    )
}