import { Form, Input, Row, Col, DatePicker, InputNumber, Button, message, Select, AutoComplete } from 'antd';
import React, { useMemo, useState } from 'react'
import FormPage from '../../../../../../components/pages/FormPage';
import moment from 'moment'
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { FiEdit } from "react-icons/fi";
import { useHistory } from 'react-router-dom';
import { useFetch } from '../../../../../../hooks/useFetch';
import { PostAPI } from '../../../../../../redux';
import { useDispatch } from 'react-redux'
import kdKemas from '../../../../../../assets/kode_kemas.json';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useSelector } from "react-redux";

const { Option } = Select;

const DefModel = {
    flight_number: null,
    flight_date: null,
    no_bc11: "",
    tgl_bc11: moment(),
    mawb_number: '',
    hawb_number: '',
    mawb_date: moment(),
    hawb_date: moment(),
    weight: 0,
    pieces: 0,
    exportir_name: '',
    no_pos_bc11: '',
    kode_kemas: undefined
}

export default () => { // eslint-disable-line

    const { consigneeCache } = useSelector(state => state.apps)
    const [localRefresh, setLocalRefresh] = useState(moment().unix())
    const [model, setModel] = useState({ ...DefModel });
    const [hoveredId, setHoveredId] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();

    const resultModel = { ...model, bruto: 0, jml_kemas: 0, tgl_bc11: moment(model?.tgl_bc11), flight_date: moment(model?.flight_date), mawb_date: moment(model?.mawb_date), hawb_date: moment(model?.hawb_date) }
    const formatAwbNumber = (value) => {
        let str = value;
        if (str.length > 4 && str.substring(3, 4) !== '-') {
            str = str.substring(0, 3) + '-' + str.substring(3);
        }
        return str
    }

    const uri = useMemo(() => {
        const r = localRefresh;
        return `api/v1/tps_import/airwaybill/${!model?.import_awb_id ? model?._id : model?.import_awb_id}?timestamp=${r}`;
    }, [model, localRefresh])

    const [rows, loading] = useFetch(uri);

    const onHawbList = (items) => {
        const { _id, bruto, jml_kemas, ...resp } = items;
        setModel({
            ...resp, awb_id: _id, pieces: jml_kemas,
            weight: bruto,
            bruto: model?.bruto, jml_kemas: model?.jml_kemas,
            _id: model?._id
        })
    }

    let options = kdKemas.map(item => ({
        ...item,
        name: `${item?.value}, ${item?.name.trim()}`
    }));

    let opts = consigneeCache.map(item => ({
        ...item,
        value: `${item?.name}`
    }))

    const deleteHawb = (id) => {
        dispatch(PostAPI({ url: 'api/v1/tps_import/delete_airwaybill', data: { id } }))
            .then(resp => {
                setLocalRefresh(moment().unix())
            })
    }

    return (
        <FormPage
            formValid={false}
            model={!!model?.flight_number ? resultModel : model}
            layout="vertical"
            setModel={setModel}
            formSize="default"
            title={"Pecah AWB"}
            emptyModel={DefModel}
            callbackPath={"/tps/import/awb"}
            onSaveEvent={(model) => {
                const {
                    awb_id,
                    flight_number,
                    flight_date,
                    no_bc11,
                    tgl_bc11,
                    mawb_number,
                    hawb_number,
                    mawb_date,
                    hawb_date,
                    jml_kemas,
                    bruto,
                    exportir_name,
                    _id,
                    origin,
                    dest,
                    flight_record_id,
                    mawb_number_fixed,
                    weight,
                    pieces,
                    no_pos_bc11,
                    kode_kemas
                } = model
                const url = 'api/v1/tps_import/airwaybill';
                dispatch(PostAPI({
                    url,
                    data: {
                        _id,
                        awb_id,
                        flight_number,
                        flight_date: moment(flight_date).format('DD-MMM-YYYY'),
                        no_bc11,
                        tgl_bc11: moment(tgl_bc11).format('YYYYMMDD'),
                        mawb_number_fixed: mawb_number_fixed || mawb_number,
                        mawb_number,
                        hawb_number,
                        mawb_date: moment(mawb_date).format('YYYYMMDD'),
                        hawb_date: moment(hawb_date).format('YYYYMMDD'),
                        jml_kemas: pieces || jml_kemas,
                        bruto: weight || bruto,
                        exportir_name,
                        origin: origin?._id,
                        dest: dest?._id,
                        flight_record_id: flight_record_id?._id,
                        no_pos_bc11,
                        kode_kemas
                    }
                })).then(resp => {
                    if (!!resp?.payload) {
                        setLocalRefresh(moment().unix())
                        setModel({
                            ...model, pieces: 0, weight: 0, awb_id: null,
                            hawb_number: '', exportir_name: '',
                            no_pos_bc11: '',
                            kode_kemas: ''
                        })
                        {
                            awb_id ?
                                message.success('edit hawb success') :
                                message.success('create hawb success')
                        }
                    }
                })
            }}
        >
            <Row>
                <Row gutter={24} style={{ width: '50%' }}>
                    <Col span={12}>
                        <Form.Item label="Flight Number" name={'flight_number'} rules={[{ required: true }]}>
                            <Input autoComplete="off" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Flight Fate" name={'flight_date'} rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' disabled />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="BC 11" name={'no_bc11'}>
                            <Input maxLength={6} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="TGL BC 11" name={'tgl_bc11'}>
                            <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Mawb Number" name={'mawb_number'}
                        >
                            <Input autoComplete="off" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Mawb Date" name={'mawb_date'} rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Hawb Number" name={'hawb_number'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Hawb Date" name={'hawb_date'} rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label={`Pieces`} name={'pieces'} rules={
                            [
                                { required: true, message: 'Pieces are required' },
                                {
                                    validator: (_, value) =>
                                        value === 0 ? Promise.reject(new Error('Pieces cannot be 0')) : Promise.resolve(),
                                },
                            ]
                        }>
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={`Weight`} name={'weight'} rules={
                            [
                                { required: true, message: 'Weight are required' },
                                {
                                    validator: (_, value) =>
                                        value === 0 ? Promise.reject(new Error('Weight cannot be 0')) : Promise.resolve(),
                                },
                            ]
                        }>
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={`Consignee`} name={'exportir_name'}>
                            <AutoComplete
                                options={opts}
                                onInput={e => e.target.value = e.target.value.toUpperCase()}
                                filterOption={(inputValue, option) => {
                                    const regex = new RegExp(inputValue, 'i');
                                    return regex.test(option.value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={"NO POS BC11"}
                            name={"no_pos_bc11"}
                            rules={[
                                {
                                    required: true,
                                    message: "NO POS BC11 wajib di isi"
                                },
                            ]}
                        >
                            <Input maxLength={12} autoComplete="off" defaultValue="" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={"KODE KEMAS"}
                            name={"kode_kemas"}
                            rules={[
                                {
                                    required: true,
                                    message: "KODE KEMAS wajib di isi"
                                },
                            ]}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {options.map((option) => (
                                    <Option key={option.value} value={option.value} label={option.name}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBlock: 16 }}>
                        <Form.Item label=" " colon={false} noStyle>
                            <Button size='large' style={{ marginInline: 16 }} icon={<SaveOutlined />} type="primary" htmlType='submit' >
                                {model?.awb_id ? "Edit" : "Save"}
                            </Button>
                            <Button size='large' icon={<ArrowLeftOutlined />} type="primary" danger onClick={() => history.goBack()} >
                                Back
                            </Button>
                        </Form.Item>
                    </div>
                </Row>
                <Col span={8} style={{ marginLeft: 50 }}>
                    <table style={{ border: '1px solid black' }}>
                        <tr style={{ border: '1px solid black' }}>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>Action</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>No</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>Awb Number</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>Hawb Number</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>BC 11</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>Pieces</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>Weight</th>
                        </tr>
                        {rows && Array.isArray(rows) && rows.map((item, idx) => (
                            <>
                                <tr style={{ border: '1px solid black' }}>
                                    <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>
                                        <FiEdit size={15}
                                            style={{ cursor: 'pointer' }}
                                            onMouseEnter={() => setHoveredId(item._id)}
                                            onMouseLeave={() => setHoveredId(null)}
                                            onClick={() => onHawbList(item)}
                                        />
                                        <RiDeleteBin5Line size={15}
                                            onMouseEnter={() => setHoveredId(item._id)}
                                            onMouseLeave={() => setHoveredId(null)}
                                            onClick={() => deleteHawb(item?._id)}
                                            style={{ marginLeft: 10, cursor: 'pointer' }}
                                        />
                                    </td>
                                    <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{idx + 1}</td>
                                    <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.mawb_number}</td>
                                    <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.hawb_number}</td>
                                    <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.no_bc11}</td>
                                    <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.jml_kemas} Pcs</td>
                                    <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.bruto} Kg</td>
                                </tr>
                            </>
                        ))}
                        <tr style={{ border: '1px solid black' }}>
                            <th colSpan={5} style={{ border: '1px solid black', fontSize: 11, width: 100 }}>Total</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>{Array.isArray(rows) && rows?.reduce((accumulator, currentValue) => accumulator + currentValue.jml_kemas, 0)} Pcs</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>{Array.isArray(rows) && rows?.reduce((accumulator, currentValue) => accumulator + currentValue.bruto, 0)} Kg</th>
                        </tr>
                    </table>
                </Col>
            </Row>
        </FormPage>
    )
}