import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'

export default () => {
    const columns = [
        {
            selector: 'id',
            name: 'Id',
            wrap: true,
        },
        {
            selector: 'cmd',
            name: 'CMD',
            wrap: true,
        },
        {
            selector: 'status',
            name: 'Status',
            wrap: true,
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'createdAt'
        }
    ]
    return (
        <TablePage
            title={"Jobs"}
            url="api/v1/job"
            actionPosition={'first'}
            columns={columns}
        />
    )
}