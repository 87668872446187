import React from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import moment from 'moment';
export default () => {
    const columns = [
        {
            selector: (row) => row?.name,
            name: "Name",
            id: 'name'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY')) || '-',
            name: "Created",
            id: 'CreatedAt'
        },
    ]
    return (
        <TablePage
            title={"Master Consignee"}
            url="api/v1/consignee"
            actionPosition={'first'}
            createPath="/master/consignee/create"
            editPath={"/master/consignee/edit"}
            columns={columns}
        />
    )
}