import AirlinePage from "./master/airlines";
import AirlineForm from "./master/airlines/form";
import AirportPage from "./master/airports";
import AirportForm from "./master/airports/form";
import ConsigneePage from "./master/consignee";
import ConsigneeForm from "./master/consignee/form";

import ConfigPage from "./master/config";
import ConfigForm from "./master/config/formConfig";

import UsersPage from "./master/users";
import UsersForm from "./master/users/form";

import FrForm from "./flight_record/form";
import ArrivalFrPage from "./flight_record/arrival";
import DepartureFrPage from "./flight_record/departure";

import VerifyExportPage from "./verify/export";
import VerifyImportPage from "./verify/import";
import VerifyDistPage from "./verify/distribusi";

// tps export
import AwbTpsExportPage from "./tps/export/export_awb";
import AwbTpsExportFrom from "./tps/export/export_awb/form";
import ExportInPage from "./tps/export/export_in";
import ExportInForm from "./tps/export/export_in/form";
import ExportOutPage from "./tps/export/export_out";
import ExportOutForm from "./tps/export/export_out/form";
import ExportCancelPage from "./tps/export/export_cancel";
import ExportCancelForm from "./tps/export/export_cancel/form";
import ExportOutList from "./tps/export/export_list";
import ExportOutListForm from "./tps/export/export_list/form";
import ExportReport from "./tps/export/export_report";

// tps import
import AwbTpsImportPage from "./tps/import/import_awb";
import AwbTpsImportForm from "./tps/import/import_awb/form";
import AwbTpsImportEditAwbForm from "./tps/import/import_awb/editawb";
import AwbTpsImportEditHeaderFlight from "./tps/import/import_awb/editHeaderFlight";
import AwbTpsImportHawbForm from "./tps/import/import_awb/hawbform";
import ImportInPage from "./tps/import/import_in/indexnew";
import ImportInForm from "./tps/import/import_in/form";
import ImportOutPage from "./tps/import/import_out/indexnew";
import ImportOutForm from "./tps/import/import_out/form";
import ImportCancelPage from "./tps/import/import_cancel";
import ImportCancelForm from "./tps/import/import_cancel/form";
import ImportOutList from "./tps/import/import_list";
import ImportOutListForm from "./tps/import/import_list/form";
import ImportReport from "./tps/import/import_report";
import ImportDocumentReport from "./tps/import/import_document_report";

// tps distribusi
import DistAwbPage from "./tps/distribusi/distribusi_awb";
import DistAwbTpsForm from "./tps/distribusi/distribusi_awb/form";
import DistAwbTpsHawbForm from "./tps/distribusi/distribusi_awb/hawbform";
import DistInPage from "./tps/distribusi/distribusi_in";
import DistInForm from "./tps/distribusi/distribusi_in/form";
import DistOutPage from "./tps/distribusi/distribusi_out";
import DistOutForm from "./tps/distribusi/distribusi_out/form";
import DistCancelPage from "./tps/distribusi/distribusi_cancel";
import DistCancelForm from "./tps/distribusi/distribusi_cancel/form";
import DistOutList from "./tps/distribusi/distribusi_list";
import DistOutListForm from "./tps/distribusi/distribusi_list/form";
import DistReport from "./tps/distribusi/distribusi_report";

// tps plp
import PlpPermPage from "./tps/plp/permohonan";
import PlpPermForm from "./tps/plp/permohonan/form";

// telex
import MasterAwb from "./telex/master_awb";
import BcInternational from "./telex/international";
import BcDomestic from "./telex/domestic";
import BcRksp from "./telex/rskp";

// beacukai
import InvenExportInven from "./beacukai/inventory/export/inventory";
import InvenExportAbandon from "./beacukai/inventory/export/abandon";
import InvenExportCurNow from "./beacukai/inventory/export/current_now";
import InvenExportCustemModul from "./beacukai/inventory/export/custome_module";

import InvenImportInven from "./beacukai/inventory/import/inventory";
import InvenImportAbandon from "./beacukai/inventory/import/abandon";
import InvenImportCurNow from "./beacukai/inventory/import/current_now";
import InvenImportCustemModul from "./beacukai/inventory/import/custome_module";

import InvenDistribusiInven from "./beacukai/inventory/distribusi/inventory";
import InvenDistribusiAbandon from "./beacukai/inventory/distribusi/abandon";
import InvenDistribusiCurNow from "./beacukai/inventory/distribusi/current_now";
import InvenDistribusiCustemModul from "./beacukai/inventory/distribusi/custome_module";

import Pagehold from "./beacukai/hold_release/hold";
import PageRelease from "./beacukai/hold_release/release";

// logs
import JobPage from "./logs/job";
import ActivityLogsPage from "./logs/activity";
import MessageLogsPage from "./logs/message";
import TelexLogPage from "./logs/telex";
import Home from "./home";
import Profile from "./profile";
import About from "./about";

export const Routing = [
  {
    to: "/home",
    level: 0x1fff,
    component: <Home />,
  },
  {
    to: "/master/airline",
    level: 0x1f00,
    component: <AirlinePage />,
  },
  {
    to: "/master/airline/create",
    level: 0x1f00,
    component: <AirlineForm />,
  },
  {
    to: "/master/airline/edit",
    level: 0x1f00,
    component: <AirlineForm />,
  },
  {
    to: "/master/airport",
    level: 0x1f00,
    component: <AirportPage />,
  },
  {
    to: "/master/airport/create",
    level: 0x1f00,
    component: <AirportForm />,
  },
  {
    to: "/master/airport/edit",
    level: 0x1f00,
    component: <AirportForm />,
  },

  {
    to: "/master/consignee",
    level: 0x1f00,
    component: <ConsigneePage />,
  },
  {
    to: "/master/consignee/create",
    level: 0x1f00,
    component: <ConsigneeForm />,
  },
  {
    to: "/master/consignee/edit",
    level: 0x1f00,
    component: <ConsigneeForm />,
  },

  {
    to: "/master/config",
    level: 0x1fff,
    component: <ConfigPage />,
  },
  {
    to: "/master/config/create",
    level: 0x1fff,
    component: <ConfigForm />,
  },
  {
    to: "/master/config/edit",
    level: 0x1fff,
    component: <ConfigForm />,
  },

  // users
  {
    to: "/master/users",
    level: 0x1fff,
    component: <UsersPage />,
  },
  {
    to: "/master/users/create",
    level: 0x1fff,
    component: <UsersForm />,
  },
  {
    to: "/master/users/edit",
    level: 0x1fff,
    component: <UsersForm />,
  },

  // flight record
  {
    to: "/fr/create",
    level: 0x1fff,
    component: <FrForm />,
  },
  {
    to: "/fr/arrival",
    level: 0x1fff,
    component: <ArrivalFrPage />,
  },
  {
    to: "/fr/departure",
    level: 0x1fff,
    component: <DepartureFrPage />,
  },

  {
    to: "/airwaybill/import/import_in",
    level: 0x1fff,
    component: <ImportInPage />,
  },
  {
    to: "/airwaybill/import/import_in/form",
    level: 0x1fff,
    component: <ImportInForm />,
  },

  // verify
  {
    to: "/verify/export",
    level: 0x1ff1,
    component: <VerifyExportPage />,
  },
  {
    to: "/verify/import",
    level: 0x1ff2,
    component: <VerifyImportPage />,
  },
  {
    to: "/verify/distribusi",
    level: 0x1ff2,
    component: <VerifyDistPage />,
  },
  {
    to: "/verify/import_document_report",
    level: 0x1ff2,
    component: <ImportDocumentReport />,
  },

  // tps export
  {
    to: "/tps/export/awb",
    level: 0x1fff,
    component: <AwbTpsExportPage />,
  },
  {
    to: "/tps/export/awb/form",
    level: 0x1fff,
    component: <AwbTpsExportFrom />,
  },
  {
    to: "/tps/export/awb/edit",
    level: 0x1fff,
    component: <AwbTpsExportFrom />,
  },
  {
    to: "/tps/export/export_in",
    level: 0x1ff1,
    component: <ExportInPage />,
  },
  {
    to: "/tps/export/export_in/form",
    level: 0x1ff1,
    component: <ExportInForm />,
  },
  {
    to: "/tps/export/export_out",
    level: 0x1ff1,
    component: <ExportOutPage />,
  },
  {
    to: "/tps/export/export_out/form",
    level: 0x1ff1,
    component: <ExportOutForm />,
  },
  {
    to: "/tps/export/export_cancel",
    level: 0x1ff1,
    component: <ExportCancelPage />,
  },
  {
    to: "/tps/export/export_cancel/form",
    level: 0x1ff1,
    component: <ExportCancelForm />,
  },
  {
    to: "/tps/export/export_list",
    level: 0x1ff5,
    component: <ExportOutList />,
  },
  {
    to: "/tps/export/export_list/form",
    level: 0x1ff5,
    component: <ExportOutListForm />,
  },
  {
    to: "/tps/export/export_report",
    level: 0x1ff1,
    component: <ExportReport />,
  },

  // tps import
  {
    to: "/tps/import/awb",
    level: 0x1fff,
    component: <AwbTpsImportPage />,
  },
  {
    to: "/tps/import/awb_form",
    level: 0x1fff,
    component: <AwbTpsImportForm />,
  },
  {
    to: "/tps/import/flight_editform",
    level: 0x1fff,
    component: <AwbTpsImportEditHeaderFlight />,
  },
  {
    to: "/tps/import/awb_edit",
    level: 0x1fff,
    component: <AwbTpsImportForm />,
  },
  {
    to: "/tps/import/awb_editform",
    level: 0x1fff,
    component: <AwbTpsImportEditAwbForm />,
  },
  {
    to: "/tps/import/awb_hawbform",
    level: 0x1fff,
    component: <AwbTpsImportHawbForm />,
  },
  {
    to: "/tps/import/import_in",
    level: 0x1ff2,
    component: <ImportInPage />,
  },
  {
    to: "/tps/import/import_in/form",
    level: 0x1ff2,
    component: <ImportInForm />,
  },
  {
    to: "/tps/import/import_out",
    level: 0x1ff2,
    component: <ImportOutPage />,
  },
  {
    to: "/tps/import/import_out/form",
    level: 0x1ff2,
    component: <ImportOutForm />,
  },
  {
    to: "/tps/import/import_cancel",
    level: 0x1ff2,
    component: <ImportCancelPage />,
  },
  {
    to: "/tps/import/import_cancel/form",
    level: 0x1ff2,
    component: <ImportCancelForm />,
  },
  {
    to: "/tps/import/import_list",
    level: 0x1ff6,
    component: <ImportOutList />,
  },
  {
    to: "/tps/import/import_list/form",
    level: 0x1ff6,
    component: <ImportOutListForm />,
  },
  {
    to: "/tps/import/import_report",
    level: 0x1ff2,
    component: <ImportReport />,
  },

  // tps distribusi
  {
    to: "/tps/distribusi/awb",
    level: 0x1fff,
    component: <DistAwbPage />,
  },
  {
    to: "/tps/distribusi/awb_form",
    level: 0x1fff,
    component: <DistAwbTpsForm />,
  },
  {
    to: "/tps/distribusi/hawbform",
    level: 0x1fff,
    component: <DistAwbTpsHawbForm />,
  },
  {
    to: "/tps/distribusi/distribusi_in",
    level: 0x1ff2,
    component: <DistInPage />,
  },
  {
    to: "/tps/distribusi/distribusi_in/form",
    level: 0x1ff2,
    component: <DistInForm />,
  },
  {
    to: "/tps/distribusi/distribusi_out",
    level: 0x1ff2,
    component: <DistOutPage />,
  },
  {
    to: "/tps/distribusi/distribusi_out/form",
    level: 0x1ff2,
    component: <DistOutForm />,
  },
  {
    to: "/tps/distribusi/distribusi_cancel",
    level: 0x1ff2,
    component: <DistCancelPage />,
  },
  {
    to: "/tps/distribusi/distribusi_cancel/form",
    level: 0x1ff2,
    component: <DistCancelForm />,
  },
  {
    to: "/tps/distribusi/distribusi_list",
    level: 0x1ff6,
    component: <DistOutList />,
  },
  {
    to: "/tps/distribusi/distribusi_list/form",
    level: 0x1ff6,
    component: <DistOutListForm />,
  },
  {
    to: "/tps/distribusi/distribusi_report",
    level: 0x1ff2,
    component: <DistReport />,
  },

  // plp
  {
    to: "/tps/plp/permohonan",
    level: 0x1ff11,
    component: <PlpPermPage />,
  },
  {
    to: "/tps/plp/permohonan/form",
    level: 0x1ff11,
    component: <PlpPermForm />,
  },

  // beacukai
  {
    to: "/telex/airwaybill",
    level: 0x1ff8,
    component: <MasterAwb />,
  },
  {
    to: "/telex/international",
    level: 0x1ffb,
    component: <BcInternational />,
  },
  {
    to: "/telex/domestic",
    level: 0x1ffb,
    component: <BcDomestic />,
  },
  {
    to: "/telex/rksp",
    level: 0x1ff8,
    component: <BcRksp />,
  },

  {
    to: "/beacukai/inventory/export/inventory",
    level: 0x1ff4,
    component: <InvenExportInven />,
  },
  {
    to: "/beacukai/inventory/export/abandon",
    level: 0x1ff4,
    component: <InvenExportAbandon />,
  },
  {
    to: "/beacukai/inventory/export/current_now",
    level: 0x1ff4,
    component: <InvenExportCurNow />,
  },
  {
    to: "/beacukai/inventory/export/custome_module",
    level: 0x1ff4,
    component: <InvenExportCustemModul />,
  },

  {
    to: "/beacukai/inventory/import/inventory",
    level: 0x1ff4,
    component: <InvenImportInven />,
  },
  {
    to: "/beacukai/inventory/import/abandon",
    level: 0x1ff4,
    component: <InvenImportAbandon />,
  },
  {
    to: "/beacukai/inventory/import/current_now",
    level: 0x1ff4,
    component: <InvenImportCurNow />,
  },
  {
    to: "/beacukai/inventory/import/custome_module",
    level: 0x1ff4,
    component: <InvenImportCustemModul />,
  },

  {
    to: "/beacukai/inventory/distribusi/inventory",
    level: 0x1ff4,
    component: <InvenDistribusiInven />,
  },
  {
    to: "/beacukai/inventory/distribusi/abandon",
    level: 0x1ff4,
    component: <InvenDistribusiAbandon />,
  },
  {
    to: "/beacukai/inventory/distribusi/current_now",
    level: 0x1ff4,
    component: <InvenDistribusiCurNow />,
  },
  {
    to: "/beacukai/inventory/distribusi/custome_module",
    level: 0x1ff4,
    component: <InvenDistribusiCustemModul />,
  },

  {
    to: "/beacukai/hold_release/hold",
    level: 0x1ff4,
    component: <Pagehold />,
  },
  {
    to: "/beacukai/hold_release/release",
    level: 0x1ff4,
    component: <PageRelease />,
  },

  // logs
  {
    to: "/logs/job",
    level: 0x1ff4,
    component: <JobPage />,
  },
  {
    to: "/logs/activity",
    level: 0x1ff4,
    component: <ActivityLogsPage />,
  },
  {
    to: "/logs/message",
    level: 0x1ff4,
    component: <MessageLogsPage />,
  },
  {
    to: "/logs/telex",
    level: 0x1ff4,
    component: <TelexLogPage />,
  },

  {
    to: "/profile",
    level: 0x1ff4,
    component: <Profile />,
  },

  //about
  {
    to: "/about",
    level: 0x1ff4,
    component: <About />,
  },
];
