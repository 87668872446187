import React, { useState } from 'react';
import moment from 'moment';
import TablePage from '../../../../../../components/pages/TablePage'
import { RiSendPlaneFill } from 'react-icons/ri';
import { Tooltip, Modal } from 'antd';
import FormTps from "./form";

export default () => {

    const [refresh, setRefresh] = useState(moment().unix())
    const [visible, setVisible] = useState(false);
    const [selecData, setSelecData] = useState(null);

    const showModalSend = (data) => {
        setVisible(true);
        setSelecData(data)
    };

    const closeModalSend = () => {
        setVisible(false);
        setSelecData(null);
    };

    const columns = [
        {
            name: "Action",
            id: '_id',
            width: "80px",
            selector: (row) => {
                return (
                    <Tooltip placement="topLeft" title={"send bea cukai"}>
                        <RiSendPlaneFill size={18} color='green' style={{ cursor: "pointer" }} onClick={() => showModalSend(row)} />
                    </Tooltip>
                )
            }
        },
        {
            selector: (row) => row?.mawb_number,
            name: "MAWB Number",
            id: 'mawb_number'
        },
        {
            selector: (row) => row?.hawb_number,
            name: "HAWB Number",
            id: 'hawb_number'
        },
        {
            selector: (row) => row?.awb_id?.flight_number,
            name: "Flight Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.awb_id?.flight_date,
            name: "Flight Date",
            id: 'flight_date'
        },
        {
            selector: (row) => <span>{row?.awb_id?.origin?.code} - {row?.awb_id?.dest?.code}</span>,
            name: "Route",
            id: 'state'
        },
        {
            selector: (row) => row?.no_dok_inout,
            name: "No Document",
            id: 'no_dok_inout',
            wrap: true,
        },
        {
            selector: (row) => row?.exportir_name,
            name: "Consignee",
            id: 'exportir_name',
            wrap: true,
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "Created Date",
            id: 'created_at'
        },
    ]
    return (
        <>
            <Modal
                title="Form Send Export Out"
                visible={visible}
                onCancel={closeModalSend}
                footer={null}
                width={"70%"}
                destroyOnClose
                style={{ marginTop: -70 }}
            >
                <FormTps data={selecData} modalClose={closeModalSend} setRefresh={setRefresh} />
            </Modal>
            <TablePage
                title={"Export Out"}
                url="api/v1/tps_export/export_out"
                actionPosition={'right'}
                columns={columns}
                refresh={refresh}
            />
        </>
    )
}